<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Funcionarios Frontera</li>
                  <li class="breadcrumb-item active">Tep Voy Por Ti</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitud Nacional</li>
                  <li class="breadcrumb-item active">Formulario</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row m-3">
                  <div class="col-md-4">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-users"></i>
                            Datos Usuario
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label for="nombre">Nombre</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.nombre"
                              readonly
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="identificacion">Identificación</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="form.identificacion"
                              readonly
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="celular">Celular</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="form.celular"
                              readonly
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="area">Área</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.area"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-bus"></i>
                            Datos Transporte
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label for="tipo_vehiculo">Tipo Vehículo</label>
                            <v-select
                              :class="[
                                $v.form.tipo_vehiculo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="form.tipo_vehiculo"
                              placeholder="Tipo Vehiculo"
                              label="nombre"
                              :options="listasForms.tipo_vehiculos"
                              class="form-control form-control-sm p-0"
                              :disabled="data_CaPa"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_ini">Fecha y Hora Inicial</label>
                            <div class="row ml-1">
                              <input
                                type="date"
                                v-model="form.fecha_ini"
                                style="font-size: 13px"
                                class="form-control form-control-sm col-md-6 mr-3"
                                :class="
                                  $v.form.fecha_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="!disabledf && data_CaPa"
                              />
                              <input
                                v-model="form.hora_ini"
                                type="time"
                                class="form-control form-control-sm col-md-5"
                                style="font-size: 13px"        
                                :class="
                                  $v.form.hora_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="!disabledf && data_CaPa"
                                @blur="validaFechasForm"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_fin">Fecha y Hora final</label>
                            <div class="row ml-1">
                              <input
                                type="date"
                                v-model="form.fecha_fin"
                                style="font-size: 13px"
                                class="form-control form-control-sm col-md-6 mr-3"
                                :class="
                                  $v.form.fecha_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="!disabledf && data_CaPa"
                              />
                              <input
                                v-model="form.hora_fin"
                                type="time"
                                class="form-control form-control-sm col-md-5"
                                style="font-size: 13px"
                                :class="
                                  $v.form.hora_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="!disabledf && data_CaPa"
                                @blur="validaFechasForm"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="gerente_area"
                              >Nombre Gerente Área</label
                            >
                            <div class="row ml-1">
                              <v-select
                                :class="[
                                  $v.form.gerente_area.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="form.gerente_area"
                                placeholder="Gerente Area"
                                label="nombres"
                                :options="listasForms.gerente"
                                class="form-control form-control-sm p-0"
                                :disabled="data_CaPa"
                              ></v-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6><i class="fas fa-street-view"></i> Locación</h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label for="origen">Origen</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.direccionOrigen"
                              :class="
                                $v.form.direccionOrigen.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="!disabledf && data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="destino">Destino</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.direccionDestino"
                              :class="
                                $v.form.direccionDestino.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="!disabledf && data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="ruta">Ruta</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.ruta"
                              :class="
                                $v.form.ruta.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="!disabledf && data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="motivo">Motivo</label>
                            <textarea
                              class="form-control form-control-sm"
                              v-model="form.motivo"
                              :disabled="!disabledf && data_CaPa"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-users"></i>
                            Datos Pasajeros
                          </h6>
                        </h3>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="datos()"
                            :disabled="data_CaPa"
                          >
                            <i class="fas fa-child mr-1"></i>
                            <small>SOY YO</small>
                          </button>
                          <div class="card-tools">
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pb-0" v-if="!data_CaPa">
                        <div
                          class="row"
                          v-if="
                            form.tipo_vehiculo &&
                              pasajeros.length <
                                form.tipo_vehiculo.cantidad_pasajeros
                          "
                        >
                          <div class="form-group col-md-2">
                            <label>N° Documento</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="formPa.numero_documento"
                              @change="getPasajero()"
                              :class="
                                $v.formPa.numero_documento.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Nombre</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="formPa.nombre"
                              :class="
                                $v.formPa.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Teléfono</label>
                            <div>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="formPa.telefono"
                                :class="
                                  $v.formPa.telefono.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="data_CaPa"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-3">
                            <label>Email</label>
                            <input
                              type="email"
                              class="form-control form-control-sm"
                              v-model="formPa.correo"
                              :class="
                                $v.formPa.correo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="data_CaPa"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Empresa / Área</label>
                            <div>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="formPa.empresa"
                                :class="
                                  $v.formPa.empresa.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="data_CaPa"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-between">
                          <div class="form-group col-md-2">
                            <button
                              v-show="!$v.formPa.$invalid"
                              type="button"
                              @click="cargarPasajero()"
                              class="btn btn-sm btn-success text-nowrap col-md-12"
                              data-html="true"
                              title="Guardar Pasajero"
                            >
                              <i class="fa fa-plus mr-1"></i>
                              <small>Guardar Pasajero</small>
                            </button>
                          </div>
                          <div class="form-group text-center col-md-6">
                            <button
                              v-if="form.tipo_vehiculo && fileFormat"
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-html="true"
                              title="Subir Lista"
                              @click="cargarPasajaeros"
                            >
                              <i class="fa fa-upload mr-1"></i>
                            </button>
                            <button
                              v-if="form.tipo_vehiculo && fileFormat"
                              type="button"
                              class="btn btn-sm bg-danger"
                              data-html="true"
                              title="Subir Lista"
                              @click="quitarLista"
                            >
                              <i class="fa fa-trash mr-1"></i>
                            </button>
                            <input
                              v-if="
                                form.tipo_vehiculo &&
                                  pasajeros.length <
                                    form.tipo_vehiculo.cantidad_pasajeros
                              "
                              ref="file_format"
                              type="file"
                              class="form-control-file btn btn-sm text-nowrap col-md-10"
                              accept=".xlsx"
                              @change="obtenerFile"
                            />
                            <span
                              v-if="
                                form.tipo_vehiculo &&
                                  pasajeros.length >=
                                    form.tipo_vehiculo.cantidad_pasajeros
                              "
                              class="badge badge-success float-center"
                              >El vehículo tipo
                              {{ form.tipo_vehiculo.nombre }} ya está
                              lleno.</span
                            >
                          </div>
                          <div class="form-group col-md-2">
                            <button
                              v-if="form.tipo_vehiculo"
                              id="formatoPasajeros"
                              type="button"
                              class="btn btn-sm bg-olive text-nowrap col-md-12"
                              data-html="true"
                              title="Guardar Pasajero"
                              @click="descargarFormatoPasajeros"
                            >
                              <i class="fa fa-file-excel mr-1"></i>
                              <small>Descargar Formato</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" v-if="pasajeros.length > 0">
                        <div class="col-md-12 form-group">
                          <table
                            id="tabla-pasajeros"
                            class="table table-bordered table-striped table-hover table-sm"
                          >
                            <thead>
                              <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">N° Documento</th>
                                <th class="text-center">Email</th>
                                <th class="text-center">Teléfono</th>
                                <th class="text-center">Empresa</th>
                                <th class="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(pasajero, index) in pasajeros"
                                :key="pasajero.id"
                              >
                                <td>
                                  <span
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <!-- Formulario para nombres -->
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="nombre"
                                      v-model="formPa2.nombre"
                                      :class="
                                        $v.formPa2.nombre.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </span>
                                  <span v-else>
                                    <!-- Dato nombre -->
                                    {{ pasajero.nombres }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <!-- Formulario para numero documento -->
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="numero_documento"
                                      v-model="formPa2.numero_documento"
                                      :class="
                                        $v.formPa2.numero_documento.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </span>
                                  <span v-else>
                                    <!-- Dato numero_documento -->
                                    {{ pasajero.numero_documento }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <!-- Formulario para correo -->
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="correo"
                                      v-model="formPa2.correo"
                                      :class="
                                        $v.formPa2.correo.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </span>
                                  <span v-else>
                                    <!-- Dato correo -->
                                    {{ pasajero.correo }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <!-- Formulario para telefono -->
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="telefono"
                                      v-model="formPa2.telefono"
                                      :class="
                                        $v.formPa2.telefono.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </span>
                                  <span v-else>
                                    <!-- Dato telefono -->
                                    {{ pasajero.telefono }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <!-- Formulario para empresa -->
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="empresa"
                                      v-model="formPa2.empresa"
                                      :class="
                                        $v.formPa2.empresa.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </span>
                                  <span v-else>
                                    <!-- Dato empresa -->
                                    {{ pasajero.empresa }}
                                  </span>
                                </td>
                                <td class="text-right">
                                  <div
                                    class="btn-group"
                                    v-if="
                                      formActualizarPa &&
                                        idActualizarPa == index
                                    "
                                  >
                                    <button
                                      v-show="!$v.formPa2.$invalid"
                                      type="button"
                                      class="btn btn-sm bg-success"
                                      @click="guardarActualizacionPa(index)"
                                      :disabled="data_CaPa"
                                    >
                                      <i class="fas fa-save"></i>
                                    </button>
                                  </div>
                                  <div class="btn-group" v-else>
                                    <!-- Botón para mostrar el formulario de actualizar -->
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-navy"
                                      @click="verFormActualizarPa(index)"
                                      :disabled="data_CaPa"
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>
                                    <!-- Botón para borrar -->
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-danger"
                                      @click="borrarPasajero(index)"
                                      :disabled="data_CaPa"
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-dollar-sign"></i> Datos Ceco y Afe
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label for="tipo_presupuesto"
                              >Tipo Presupuesto</label
                            >
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="[
                                $v.formCeAf.tipo_presupuesto.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="formCeAf.tipo_presupuesto"
                              placeholder="Tipo Presupuesto"
                              label="descripcion"
                              :options="listasForms.tipo_presupuesto"
                              :disabled="data_CaPa"
                              @input="validaFormCecoAfe('tipo_presupuesto')"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="numero">Código Contable</label>
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="[
                                $v.formCeAf.numero.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="formCeAf.numero"
                              placeholder="Código Contable"
                              label="codigo_contable"
                              :options="listasForms.lst_numero"
                              :disabled="data_CaPa"
                              :readonly="!formCeAf.tipo_presupuesto"
                              @input="validaFormCecoAfe('numero')"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-6">
                            <label for="responsable">Responsable</label>
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="[
                                $v.formCeAf.responsable.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="formCeAf.responsable"
                              placeholder="Responsable"
                              label="nombres"
                              :options="listasForms.respon"
                              :disabled="data_CaPa"
                              :readonly="!formCeAf.numero"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="porcentaje">Porcentaje</label>
                            <div class="input-group">
                              <input
                                class="form-control form-control-sm"
                                type="number"
                                min="0"
                                max="100"
                                v-model="formCeAf.porcentaje"
                                :class="
                                  $v.formCeAf.porcentaje.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="data_CaPa"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text form-control-sm"
                                  >%</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="form-group mt-4 col-md-1">
                            <button
                              v-show="!$v.formCeAf.$invalid"
                              type="button"
                              @click="cargarCeco()"
                              class="btn btn-sm btn-success text-nowrap"
                              data-html="true"
                              data-toggle="tooltip"
                              :disabled="data_CaPa"
                            >
                              <i class="fa fa-plus"></i>
                              Guardar Ceco Afe
                            </button>
                          </div>
                          <div
                            class="col-md-12 mb-0 text-center"
                            :class="
                              total_porcentaje == 100
                                ? 'text-success'
                                : total_porcentaje < 100
                                ? 'text-muted'
                                : 'text-danger'
                            "
                          >
                            <h4>Porcentaje Total: {{ total_porcentaje }}%</h4>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="cecosAfes.length > 0">
                          <div id="tabla pasajeros">
                            <div class="card-body p-0">
                              <div class="col-md-12 form-group">
                                <table
                                  id="tabla-pasajeros"
                                  class="table table-bordered table-striped table-hover table-sm"
                                >
                                  <thead>
                                    <tr>
                                      <th class="text-center">
                                        Tipo Presupuesto
                                      </th>
                                      <th class="text-center">
                                        Código Contable
                                      </th>
                                      <th class="text-center">Responsable</th>
                                      <th class="text-center">Porcentaje</th>
                                      <th class="text-center">Acciones</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(cecoAfe, index) in cecosAfes"
                                      :key="cecoAfe.id"
                                    >
                                      <td class="text-center">
                                        <span
                                          class="badge"
                                          :class="
                                            cecoAfe.tipo_presupuesto
                                              .numeracion == 1 ||
                                            cecoAfe.tipo_presupuesto == 1
                                              ? 'bg-info'
                                              : cecoAfe.tipo_presupuesto
                                                  .numeracion == 2 ||
                                                cecoAfe.tipo_presupuesto == 2
                                              ? 'bg-navy'
                                              : 'bg-lime'
                                          "
                                          >{{ cecoAfe.nTipoPresupuesto }}</span
                                        >
                                      </td>
                                      <td>
                                        <span
                                          v-if="
                                            formActualizarCA &&
                                              idActualizarCA == index
                                          "
                                        >
                                          <!-- Formulario para codigo_contable -->
                                          <v-select
                                            class="form-control form-control-sm p-0"
                                            :class="[
                                              $v.formCeAf2.numero.$invalid
                                                ? 'is-invalid'
                                                : 'is-valid',
                                              $store.getters.getDarkMode
                                                ? 'dark-vselect'
                                                : '',
                                            ]"
                                            v-model="formCeAf2.numero"
                                            placeholder="Código Contable"
                                            label="codigo_contable"
                                            :options="listasForms2.lst_numero"
                                            @input="validaFormCecoAfe2()"
                                          ></v-select>
                                        </span>
                                        <span v-else>
                                          <!-- Dato codigo_contable -->
                                          {{ cecoAfe.numero.codigo_contable }}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          v-if="
                                            formActualizarCA &&
                                              idActualizarCA == index
                                          "
                                        >
                                          <!-- Formulario para nombre -->
                                          <v-select
                                            :class="[
                                              $v.formCeAf2.responsable.$invalid
                                                ? 'is-invalid'
                                                : 'is-valid',
                                              $store.getters.getDarkMode
                                                ? 'dark-vselect'
                                                : '',
                                            ]"
                                            v-model="formCeAf2.responsable"
                                            placeholder="Responsable"
                                            label="nombres"
                                            :options="listasForms2.respon"
                                            class="form-control form-control-sm p-0"
                                          ></v-select>
                                        </span>
                                        <span v-else>
                                          <!-- Dato nombre -->
                                          {{ cecoAfe.responsable.nombres }}
                                          {{ cecoAfe.responsable.apellidos }}
                                        </span>
                                      </td>
                                      <td class="text-right">
                                        <span
                                          v-if="
                                            formActualizarCA &&
                                              idActualizarCA == index
                                          "
                                        >
                                          <!-- Formulario para porcentaje -->
                                          <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            id="porcentaje"
                                            v-model="formCeAf2.porcentaje"
                                            :class="
                                              $v.formCeAf2.porcentaje.$invalid
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            "
                                          />
                                        </span>
                                        <span v-else>
                                          <!-- Dato porcentaje -->
                                          {{ cecoAfe.porcentaje }}%
                                        </span>
                                      </td>
                                      <td class="text-right">
                                        <div
                                          class="btn-group"
                                          v-if="
                                            formActualizarCA &&
                                              idActualizarCA == index
                                          "
                                        >
                                          <button
                                            v-show="!$v.formCeAf2.$invalid"
                                            type="button"
                                            class="btn btn-sm bg-success"
                                            @click="
                                              guardarActualizacionCecoAfe(index)
                                            "
                                            :disabled="data_CaPa"
                                          >
                                            <i class="fas fa-save"></i>
                                          </button>
                                        </div>
                                        <div class="btn-group" v-else>
                                          <!-- Botón para mostrar el formulario de actualizar -->
                                          <button
                                            type="button"
                                            class="btn btn-sm bg-navy"
                                            @click="
                                              verFormActualizarCecoAfe(index)
                                            "
                                            :disabled="
                                              (formActualizarCA &&
                                                idActualizarCA != index) ||
                                                data_CaPa
                                            "
                                          >
                                            <i class="fas fa-edit"></i>
                                          </button>
                                          <!-- Botón para borrar -->
                                          <button
                                            type="button"
                                            class="btn btn-sm btn-danger"
                                            @click="borrarCecoAfe(index)"
                                            :disabled="
                                              (formActualizarCA &&
                                                idActualizarCA != index) ||
                                                data_CaPa
                                            "
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn bg-primary col-md-1"
                  @click="save()"
                  v-if="
                    !$v.form.$invalid &&
                      this.pasajeros.length > 0 &&
                      this.total_porcentaje == 100 &&
                      ($store.getters.can(
                        'funcionariofrontera.solicitudNacional.create'
                      ) ||
                        $store.getters.can(
                          'funcionariofrontera.solicitudNacional.edit'
                        ))
                  "
                  :disabled="!disabledf && data_CaPa"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import {
  required,
  email,
  maxLength,
  minValue,
  maxValue,
  regex,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "FuncionarioSolicitudNacionalesForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      data_CaPa: this.$route.params.accion == "Editar",
      total_porcentaje: 0,
      disabledf: false,
      responsable: [],
      cecosAfes: [],
      tipo_vehiculo: [],
      formActualizarCA: false,
      idActualizarCA: -1,
      fileFormat: null,
      formActualizarPa: false,
      idActualizarPa: -1,
      pasajeros: [],
      formCeAf: {
        responsable: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      formCeAf2: {
        responsable: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      formPa: {
        nombre: null,
        numero_documento: null,
        correo: null,
        telefono: null,
        empresa: null,
      },
      formPa2: {
        nombre: null,
        numero_documento: null,
        correo: null,
        telefono: null,
        empresa: null,
      },
      form: {
        //origen_id: null,
        //destino_id: null,
        nombre: "",
        identificacion: null,
        celular: null,
        area: "",
        tipo_vehiculo: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        gerente_area: null,
        //geocerca_origen_id: null,
        //geocerca_destino_id: null,
        sitio: null,
        direccionOrigen: null,
        direccionDestino: null,
        ruta: null,
        //long_destino: null,
        //lat_destino: null,
        //long_origen: null,
        //lat_origen: null,
        //sitio_origen_type: null,
        //sitio_destino_type: null,
        metodo: null,
      },
      form1: {},
      user: [],
      pasajero: [],
      metodo: "",
      listasForms: {
        tipo_vehiculos: [],
        gerente: [],
        ciudades: [],
        geocercas_zonas: [],
        respon: [],
        lst_numero: [],
        filteredList: [],
        tipo_presupuesto: [],
      },
      listasForms2: {
        lst_numero: [],
        respon: [],
      },
      uri_docs: process.env.VUE_APP_API_URL,
      cargando: false,
    };
  },
  validations: {
    form: {
      tipo_vehiculo: {
        required,
      },
      fecha_ini: {
        required,
        validFormat: (value) =>
          /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ),
      },
      hora_ini: {
        required,
      },
      fecha_fin: {
        required,
        validFormat: (value) =>
          /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ),
      },
      hora_fin: {
        required,
      },
      gerente_area: {
        required,
      },
      direccionOrigen: {
        required,
      },
      direccionDestino: {
        required,
      },
      ruta: {
        required,
      },
    },

    formCeAf: {
      responsable: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      porcentaje: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100),
      },
      numero: {
        required,
      },
    },

    formCeAf2: {
      responsable: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      porcentaje: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100),
      },
      numero: {
        required,
      },
    },

    formPa: {
      nombre: {
        required,
      },
      numero_documento: {
        required,
      },
      correo: {
        required,
        email,
      },
      telefono: {
        required,
        maxLength: maxLength(10),
      },
      empresa: {
        required,
      },
    },

    formPa2: {
      nombre: {
        required,
      },
      numero_documento: {
        required,
      },
      correo: {
        required,
      },
      telefono: {
        required,
      },
      empresa: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form1 = this.$route.params.data_edit;

        this.form.user_id = this.form1.user.id;
        this.form.nombre = this.form1.user.name;
        this.form.identificacion = this.form1.user.n_document;
        this.form.celular = this.form1.user.cel;
        this.form.area = this.form1.user.funcionario[0]
          ? this.form1.user.funcionario[0].nArea
          : null;
        this.form.id = this.form1.id;
        this.form.tipo_operacion = this.form1.tipo_operacion;
        this.form.sitio = this.form1.sitio;
        this.form.tipo_vehiculo = {
          id: this.form1.tipo_vehiculo.id,
          nombre: this.form1.tipo_vehiculo.nombre,
          cantidad_pasajeros: this.form1.tipo_vehiculo.cantidad_pasajeros,
        };
        this.form.fecha_ini = this.form1.fecha_hora_inicio_servicio.slice(
          0,
          10
        );
        this.form.hora_ini = this.form1.fecha_hora_inicio_servicio.slice(
          11,
          19
        );
        this.form.fecha_fin = this.form1.fecha_hora_fin_servicio.slice(0, 10);
        this.form.hora_fin = this.form1.fecha_hora_fin_servicio.slice(11, 19);

        this.form.direccionOrigen = this.form1.direccion_origen;
        this.form.direccionDestino = this.form1.direccion_destino;
        this.form.ruta = this.form1.ruta;
        this.form.motivo = this.form1.motivo;

        this.form.gerente_area = {};
        if (this.form1.funcionario_gerente) {
          this.form.gerente_area = {
            id: this.form1.funcionario_gerente.id,
            nombres:
              this.form1.funcionario_gerente.nombres +
              " " +
              this.form1.funcionario_gerente.apellidos,
            user_id: this.form1.funcionario_gerente.user_id,
          };
        }

        await this.llenarcecoAfe();
        this.llenarpasajero();
        this.cambioFecha();

        await this.getUser();
        this.cargando = false;
      } else {
        this.metodo = "POST";
        await this.getUser();
        this.cargando = false;
      }
    },

    back() {
      return this.$router.replace("/FuncionariosFrontera/solicitudNacional");
    },

    porcentajeTotal() {
      if (this.formCeAf.porcentaje) {
        this.total_porcentaje += Number(this.formCeAf.porcentaje);
        // Se valida el total del porcentaje si es mayor a 100%
        if (this.total_porcentaje > 100) {
          this.$swal({
            title: "Excedio la cantidad de ceco y afe permitida",
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });
          this.total_porcentaje -= Number(this.formCeAf.porcentaje);
          this.cecosAfes.pop();
        }
      }
    },

    cantPasajeros() {
      // Se valida la cantidad de pasajero dependiendo el tipo de vehiculo
      if (this.pasajeros.length > this.form.tipo_vehiculo.cantidad_pasajeros) {
        this.$swal({
          title: "Excedio la cantidad de pasajeros permitida",
          text:
            "La cantidad máxima de pasajeros para el tipo de vehiculo " +
            " " +
            this.form.tipo_vehiculo.nombre +
            " " +
            "son" +
            " " +
            " " +
            this.form.tipo_vehiculo.cantidad_pasajeros,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.pasajeros.pop();
      }
    },

    llenarcecoAfe() {
      let datos = {};
      let porcentaje = 0;

      for (let c = 0; c < this.form1.ceco_afe.length; c++) {
        porcentaje += this.form1.ceco_afe[c].porcentaje;
        datos = {
          tipo_presupuesto: this.form1.ceco_afe[c].tipo_presupuesto,
          nTipoPresupuesto: this.form1.ceco_afe[c].nTipoPresupuesto,
          numero: this.form1.ceco_afe[c].numero,
          porcentaje: this.form1.ceco_afe[c].porcentaje,
          id: this.form1.ceco_afe[c].id,
        };
        this.cecosAfes.push(datos);
      }
      this.total_porcentaje = porcentaje;
    },

    llenarpasajero() {
      let datos = {};

      for (let z = 0; z < this.form1.pasajeros.length; z++) {
        datos = {
          nombres: this.form1.pasajeros[z].nombres,
          numero_documento: this.form1.pasajeros[z].numero_documento,
          telefono: this.form1.pasajeros[z].telefono,
          correo: this.form1.pasajeros[z].correo,
          empresa: this.form1.pasajeros[z].empresa_id,
          id: this.form1.pasajeros[z].id,
        };

        this.pasajeros.push(datos);
      }
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getGerente() {
      axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            gerente_area: 1,
          },
        })
        .then((response) => {
          this.listasForms.gerente = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    getNumeroContable(filtros = null) {
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.lst_numero = response.data;
        });
    },

    getResponsable() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms.respon = response.data;
        });
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = this.user.id;
      this.form.nombre = this.user.name;
      this.form.identificacion = this.user.n_document;
      this.form.celular = this.user.cel;
      this.form.area = this.user.funcionario[0]
        ? this.user.funcionario[0].nArea
        : null;
    },

    getPasajero() {
      axios
        .get("/api/funcionariosFrontera/getPasajero", {
          params: {
            numero_documento: this.formPa.numero_documento,
          },
        })
        .then((response) => {
          let pasajero = response.data;

          if (pasajero) {
            this.formPa.funcionario_id = pasajero.id;
            let nombres = pasajero.nombres ? pasajero.nombres : "";
            let apellidos = pasajero.apellidos ? pasajero.apellidos : "";
            this.formPa.nombre = nombres + " " + apellidos;

            this.formPa.correo = pasajero.email;
            this.formPa.telefono = pasajero.celular_principal;
            this.formPa.empresa = pasajero.nArea;
          }
        });
    },

    validaFormCecoAfe(campo = null) {
      switch (campo) {
        case "tipo_presupuesto":
          if (this.formCeAf.tipo_presupuesto) {
            if (
              this.formCeAf.numero &&
              this.formCeAf.numero.tipo_presupuesto !=
                this.formCeAf.tipo_presupuesto.numeracion
            ) {
              this.formCeAf.numero = null;
              this.formCeAf.responsable = null;
            }
            this.getNumeroContable({
              tipo_presupuesto:
                this.formCeAf.tipo_presupuesto.numeracion == 1 ? 1 : 2,
            });
          } else {
            // Se inicial las listas y campos
            this.listasForms.lst_numero = [];
            this.listasForms.respon = [];
            this.formCeAf.numero = null;
            this.formCeAf.responsable = null;
          }
          break;

        case "numero":
          if (this.formCeAf.numero) {
            if (
              this.formCeAf.responsable &&
              this.formCeAf.responsable.id !=
                this.formCeAf.numero.funcionario_id
            ) {
              this.formCeAf.responsable = null;
            }
            // Se valida si existe un funcionario dentro de la numeracion contable
            this.listasForms.respon = [];
            this.getResponsable();
            this.formCeAf.responsable = null;
            /* if (this.formCeAf.numero.funcionario) {
              const data_respons = {
                id: this.formCeAf.numero.funcionario.id,
                nombres: `${this.formCeAf.numero.funcionario.nombres} ${this.formCeAf.numero.funcionario.apellidos}`,
                user_id: this.formCeAf.numero.funcionario.user_id,
              };
              this.listasForms.respon.push(data_respons);
              this.formCeAf.responsable = data_respons;
            } else {
              this.getResponsable();
            } */
          } else {
            // Se inicial las listas y campos
            this.listasForms.respon = [];
            this.formCeAf.responsable = null;
          }
          break;

        default:
          break;
      }
    },

    validaFechasForm() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.form.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha final no puede ser menor a la fecha inicial..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.hora_ini) {
        let fecha_actual = moment().format("YYYY-MM-DD");
        let hora_actual = moment().format("HH:mm:ss");
        let actual = moment(fecha_actual + " " + hora_actual);
        let fecha_soli = moment(this.form.fecha_ini + " " + this.form.hora_ini);
        let diff = fecha_soli.diff(actual, "m");

        if (diff < 1440) {
          this.form.fecha_ini = null;
          this.form.hora_ini = null;
          this.form.fecha_fin = null;
          this.form.hora_fin = null;
          this.$swal({
            icon: "info",
            html: `Estimado Usuario, Su solicitud no cumple las 24 horas de anticipación al inicio del servicio, tiempo requerido para realizar una correcta coordinación, inspección y postulación de vehículo y conductor. Si su servicio es de carácter urgente, requerimos que su Gerente nos de la aprobación por medio de correo electrónico para gestionar el requerimiento como emergente.`,
            confirmButtonText: "Aceptar",
            timerProgressBar: true,
          });
        }
      }
      if (this.form.hora_fin) {
        if (this.form.hora_fin >= "20:00:00") {
          this.$swal({
            text:
              "Tenga en cuenta que el recorrido de FRONTERA va hasta las 20:00.!!",
            icon: "info",
            confirmButtonText: "Aceptar!",
          });
        }
      }
    },

    cambioFecha() {
      let hoy = moment()
        .add(-6, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      let fecha = this.$route.params.data_edit.fecha_hora_inicio_servicio;

      if (hoy < fecha) {
        this.disabledf = true;
      } else {
        this.disabledf = false;
      }
    },

    cargarCeco() {
      // Se agrega a nuestra lista
      this.cecosAfes.push({
        id: null,
        responsable: this.formCeAf.responsable,
        tipo_presupuesto: this.formCeAf.tipo_presupuesto,
        nTipoPresupuesto: this.formCeAf.tipo_presupuesto.descripcion,
        porcentaje: this.formCeAf.porcentaje,
        numero: this.formCeAf.numero,
      });
      this.porcentajeTotal();
      // Vaciamos el formulario de añadir
      this.formCeAf.responsable = "";
      this.formCeAf.porcentaje = "";
      this.formCeAf.tipo_presupuesto = "";
      this.formCeAf.numero = "";
      this.listasForms.lst_numero = [];
      this.listasForms.respon = [];
    },

    verFormActualizarCecoAfe(cecosAfes_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarCA = cecosAfes_id;
      this.formCeAf2.responsable = this.cecosAfes[cecosAfes_id].responsable;
      this.formCeAf2.tipo_presupuesto = this.cecosAfes[
        cecosAfes_id
      ].tipo_presupuesto;
      this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
      this.formCeAf2.numero = this.cecosAfes[cecosAfes_id].numero;
      // Mostramos el formulario
      this.formActualizarCA = true;
      // Se valida la lista del formulario
      this.getNumeroContable2({
        tipo_presupuesto:
          this.cecosAfes[cecosAfes_id].tipo_presupuesto.numeracion == 1 ? 1 : 2,
      });

      // Se valida si existe un funcionario dentro de la numeracion contable
      this.listasForms2.respon = [];
      if (this.cecosAfes[cecosAfes_id].numero.funcionario) {
        this.listasForms2.respon.push({
          id: this.cecosAfes[cecosAfes_id].numero.funcionario.id,
          nombres: `${this.cecosAfes[cecosAfes_id].numero.funcionario.nombres} ${this.cecosAfes[cecosAfes_id].numero.funcionario.apellidos}`,
          user_id: this.cecosAfes[cecosAfes_id].numero.funcionario.user_id,
        });
      } else {
        this.getResponsable2();
      }
    },

    getNumeroContable2(filtros = null) {
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms2.lst_numero = response.data;
        });
    },

    getResponsable2() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms2.respon = response.data;
        });
    },

    validaFormCecoAfe2() {
      if (this.formCeAf2.numero) {
        if (
          this.formCeAf2.responsable &&
          this.formCeAf2.responsable.id != this.formCeAf2.numero.funcionario_id
        ) {
          this.formCeAf2.responsable = null;
        }
        // Se valida si existe un funcionario dentro de la numeracion contable
        this.listasForms2.respon = [];
        this.formCeAf2.responsable = null;
        if (this.formCeAf2.numero.funcionario) {
          const data_respons = {
            id: this.formCeAf2.numero.funcionario.id,
            nombres: `${this.formCeAf2.numero.funcionario.nombres} ${this.formCeAf2.numero.funcionario.apellidos}`,
            user_id: this.formCeAf2.numero.funcionario.user_id,
          };
          this.listasForms2.respon.push(data_respons);
          this.formCeAf2.responsable = data_respons;
        } else {
          this.getResponsable2();
        }
      } else {
        // Se inicial las listas y campos
        this.listasForms2.respon = [];
        this.formCeAf2.responsable = null;
      }
    },

    guardarActualizacionCecoAfe(cecosAfes_id) {
      // Se valida el valor del porcentaje
      let flag_porcentaje = this.total_porcentaje;
      flag_porcentaje -= Number(this.cecosAfes[cecosAfes_id].porcentaje);
      flag_porcentaje += Number(this.formCeAf2.porcentaje);

      if (flag_porcentaje > 100) {
        this.$swal({
          title: "Excedió la cantidad de ceco y afe permitida",
          text: `El límite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        flag_porcentaje -= Number(this.formCeAf2.porcentaje);
        this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
        flag_porcentaje += Number(this.formCeAf2.porcentaje);
      } else {
        // Ocultamos nuestro formulario de actualizar
        this.formActualizarCA = false;
        // Actualizamos los datos
        this.cecosAfes[cecosAfes_id].responsable = this.formCeAf2.responsable;
        this.cecosAfes[
          cecosAfes_id
        ].tipo_presupuesto = this.formCeAf2.tipo_presupuesto;
        this.cecosAfes[cecosAfes_id].porcentaje = this.formCeAf2.porcentaje;
        this.cecosAfes[cecosAfes_id].numero = this.formCeAf2.numero;
      }
      // Se actualiza el valor del porcentaje
      this.total_porcentaje = flag_porcentaje;
    },

    borrarCecoAfe(cecosAfes_id) {
      this.$swal({
        title: "Está seguro de quitar el Ceco o Afe?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          this.total_porcentaje -= this.cecosAfes[cecosAfes_id].porcentaje;
          // Borramos de la lista
          this.cecosAfes.splice(cecosAfes_id, 1);
        }
      });
    },

    async datos() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
        this.formPa.user_id = this.user.id;
        this.formPa.nombre = this.user.name;
        this.formPa.numero_documento = this.user.n_document;
        this.formPa.telefono = this.user.cel;
        this.formPa.correo = this.user.email;
        this.formPa.empresa = "FRONTERA";
      });
    },

    /* descargarFormatoPasajeros() {
      window.open(
        process.env.VUE_APP_API_URL + "/exports/formatoTepListaPasajeros.xlsx",
        "_blank"
      );
    },
 */

    descargarFormatoPasajeros() {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/funcionariosFrontera/solicitudNacional/exportPlantilla",
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
    
    obtenerFile(e) {
      this.fileFormat = null;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileFormat = file;
        }
        this.cargando = false;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFormat = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    quitarLista() {
      this.fileFormat = null;
      this.$refs.file_format.value = null;
    },

    cargarPasajaeros() {
      this.cargando = true;
      let me = this;
      let formData = new FormData();
      formData.append("file_formato", this.fileFormat);
      formData.append(
        "pasajeros_vehiculo",
        this.form.tipo_vehiculo.cantidad_pasajeros
      );
      formData.append("pasajeros_actuales", this.pasajeros.length);
      axios
        .post(
          "/api/funcionariosFrontera/solicitudNacional/cargarPasajeros",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            Object.assign(this.pasajeros, response.data.resultado_archivo);
            me.$swal({
              icon: "success",
              title: "Los datos se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            let content_error = "<h4>Errores encontrados.</h4>";
            response.data.error.forEach(
              (err) => (content_error += `<div><small>${err}</small></div>`)
            );
            this.$swal({
              title: "Error en el contenido del archivo",
              html: content_error,
              icon: "error",
              showConfirmButton: false,
              timer: 2000 + 900 * response.data.error.length,
              backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            });
          }
          this.quitarLista();
          me.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cargarPasajero() {
      // Se agrega a nuestra lista
      if (this.form.tipo_vehiculo) {
        this.pasajeros.push({
          id: +new Date(),
          nombres: this.formPa.nombre,
          numero_documento: this.formPa.numero_documento,
          correo: this.formPa.correo,
          telefono: this.formPa.telefono,
          empresa: this.formPa.empresa,
          funcionario_id: this.formPa.funcionario_id,
        });
        this.cantPasajeros();
        // Vaciamos el formulario de añadir
        this.formPa.nombre = "";
        this.formPa.numero_documento = "";
        this.formPa.correo = "";
        this.formPa.telefono = "";
        this.formPa.empresa = "";
      } else {
        this.$swal({
          icon: "error",
          title: "Debe seleccionar primero el tipo de vehículo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    verFormActualizarPa(pasajeros_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarPa = pasajeros_id;
      this.formPa2.nombre = this.pasajeros[pasajeros_id].nombres;
      this.formPa2.numero_documento = this.pasajeros[
        pasajeros_id
      ].numero_documento;
      this.formPa2.telefono = this.pasajeros[pasajeros_id].telefono;
      this.formPa2.correo = this.pasajeros[pasajeros_id].correo;
      this.formPa2.empresa = this.pasajeros[pasajeros_id].empresa;
      // Mostramos el formulario
      this.formActualizarPa = true;
    },

    guardarActualizacionPa(pasajeros_id) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizarPa = false;
      // Actualizamos los datos
      this.pasajeros[pasajeros_id].nombres = this.formPa2.nombre;
      this.pasajeros[
        pasajeros_id
      ].numero_documento = this.formPa2.numero_documento;
      this.pasajeros[pasajeros_id].telefono = this.formPa2.telefono;
      this.pasajeros[pasajeros_id].correo = this.formPa2.correo;
      this.pasajeros[pasajeros_id].empresa = this.formPa2.empresa;
      this.pasajeros[pasajeros_id].funcionario_id = this.formPa2.funcionario_id;
    },

    borrarPasajero(pasajeros_id) {
      this.$swal({
        title: "Está seguro de quitar el pasajero?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          // Borramos de la lista
          this.pasajeros.splice(pasajeros_id, 1);
        }
      });
    },

    async save() {
      let datos = {
        id: this.form.id,
        user_id: this.user.id,
        tipo_vehiculo: this.form.tipo_vehiculo.id,
        fecha_hora_inicio_servicio:
          this.form.fecha_ini + " " + this.form.hora_ini,
        fecha_hora_fin_servicio: this.form.fecha_fin + " " + this.form.hora_fin,
        gerente_area: this.form.gerente_area.id,
        gerente_area_user: this.form.gerente_area.user_id,
        direccionOrigen: this.form.direccionOrigen,
        direccionDestino: this.form.direccionDestino,
        cant_pasajeros: this.pasajeros.length,
        pasajero: this.pasajeros,
        cecosAfes: this.cecosAfes,
        metodo: this.metodo,
        motivo: this.form.motivo,
        ruta: this.form.ruta,
      };

      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/solicitudNacional",
          data: datos,
        })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Se actualizó la solicitud exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
            this.back();
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.back();
          });
      }
    },
  },
  async mounted() {
    await this.getTipoPresupuesto();
    await this.init();
    await this.getTipoVehiculo();
    await this.getGerente();
  },
};
</script>
